// -----------------------------
// CB Band
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/font.scss";
@import "../mixins/typography.scss";

.cb-band {
  // ------------------------------------ HERO
  &.cb-band-hero {
    .row {
      &:first-of-type {
        min-height: 576px;

        @include media-breakpoint-down("md", $grid-breakpoints) {
          min-height: 552px;
        }

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          min-height: auto;
        }

        align-items: center;
      }

      &.cb-band-hero-height-auto {
        min-height: auto !important;
      }
    }

    &.cb-band-hero-bg,
    .cb-band-hero-bg {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.cb-band-hero-xs-stack {
      .cb-band-hero-content {
        padding: 0;
      }
    }

    .cb-band-hero-img-xs {
      display: none;
    }

    .cb-band-hero-content {
      padding: 72px 0;

      @include media-breakpoint-only("xs", $grid-breakpoints) {
        padding: 48px 0;
      }

      .cb-text-overline {
        font-size: 1rem;
        line-height: 1.5em;

        display: inline-flex;
        padding-top: 8px;

        margin-bottom: 24px;

        border-width: 0;
        border-top-width: 1px;
        border-style: solid;

        @include media-breakpoint-only("md", $grid-breakpoints) {
          margin-bottom: 16px;
        }

        @include media-breakpoint-only("sm", $grid-breakpoints) {
          margin-bottom: 8px;
        }
      }

      .cb-band-hero-title {
        font-weight: 400;
      }

      .cb-band-hero-desc {
        @include cb-paragraph2-res();
        font-weight: 300;
      }

      .cb-band-hero-title {
        + .cb-band-hero-desc {
          margin-top: 24px;
        }
      }

      .cb-text-overline {
        + .cb-band-hero-desc {
          margin-top: 0;
        }
      }

      .cb-btn {
        font-size: 1rem;
        line-height: 1.5em;

        @each $breakpoint, $value in $grid-breakpoints {
          @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint} {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }

          @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint}-up {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }

          @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
            &.cb-btn-sm-#{$breakpoint}-down {
              font-size: $btn-font-size;
              line-height: $btn-line-height;
            }
          }
        }
      }

      .cb-btn-row {
        margin-top: 48px;
      }
    }

    // Band specific Mobile treatment
    // Mobile
    @include media-breakpoint-only("xs", $grid-breakpoints) {
      .cb-band-hero-content {
        text-align: left;

        .cb-btn-row {
          margin-top: 32px;
        }

        .cb-btn {
          font-size: 0.875rem;
          line-height: 1.7142857142857142em;
        }
      }

      .cb-band-hero-img-xs {
        display: flex;
        flex-basis: auto;
        align-items: center;
        justify-content: center;
      }
      &.cb-band-hero-xs-stack {
        .row {
          &:first-of-type {
            margin: 0 -24px;
          }
        }

        div[class^="col-"]:not(.cb-band-hero-content) {
          padding-left: 0;
          padding-right: 0;
        }

        .cb-band-hero-content {
          padding: 48px 24px;
        }

        &.cb-band-hero-bg,
        .cb-band-hero-bg {
          background-image: none !important;
        }
      }
    }
  }

  // ------------------------------------ PANEL
  &.cb-band-panel {
    padding: 72px 0;

    .cb-band-panel-content {
      .cb-band-panel-header {
        .cb-band-panel-title {
          font-weight: 400;

          &:not(h2) {
            letter-spacing: -0.5px;
            font-size: 1.5rem; //24
            line-height: 1.3333333333333333em; //32

            // sm, md
            @include media-breakpoint-up("sm", $grid-breakpoints) {
              font-size: 2rem; //32
              line-height: 1.25em; //40
            }

            //lg, xl, 2xl, 3xl
            @include media-breakpoint-up("lg", $grid-breakpoints) {
              font-size: 2.5rem; //40
              line-height: 1.2em; //48
            }
          }
        }

        .cb-text-overline {
          padding-top: 8px;
          display: inline-flex;
          font-size: 1rem;
          line-height: 1.5em;
          border-top: 1px solid $black1;
          margin-bottom: 24px;
        }
      }

      .cb-band-panel-desc {
        margin-top: 8px;

        // xs
        font-size: 1rem;
        line-height: 1.5em;

        // lg and up
        @include media-breakpoint-up("lg", $grid-breakpoints) {
          font-size: 1.3125rem; //21
          line-height: 1.5238095238095237em; //32
        }
      }

      .cb-band-panel-line {
        border-top: 1px solid $black1;
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .cb-band-panel-header {
        + .cb-band-panel-components {
          margin-top: 48px;
        }
      }

      .cb-band-panel-footer {
        margin-top: 48px;
      }
    }

    @include media-breakpoint-between("md", "lg", $grid-breakpoints) {
      .cb-band-panel-50-2 {
        padding-left: 24px;
      }
      .cb-band-panel-50-1 {
        padding-right: 24px;
        &.order-md-last {
          padding-left: 24px;
          padding-right: 12px;
          + .cb-band-panel-50-2 {
            padding-right: 24px;
            padding-left: 12px;
          }
        }
      }
    }

    @include media-breakpoint-up("xl", $grid-breakpoints) {
      .cb-band-panel-50-2 {
        padding-left: 36px;
      }
      .cb-band-panel-50-1 {
        padding-right: 36px;
        &.order-md-last {
          padding-left: 36px;
          padding-right: 12px;
          + .cb-band-panel-50-2 {
            padding-right: 36px;
            padding-left: 12px;
          }
        }
      }
    }

    &.cb-band-panel-image {
      background-size: cover;
      min-height: 580px;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;

      &.cb-band-panel-image-dark {
        position: relative;

        &::before {
          position: absolute;
          content: " ";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    &.cb-band-panel-radius-24 {
      position: relative;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
      margin-top: -24px;
    }
  }
}

// ------------------------------------ HERO OPTIONS
.cb-band {
  &.cb-band-hero {
    // ------------------------------------ ARTICLE
    &.cb-band-hero-article {
      .cb-band-hero-content {
        .cb-band-hero-title {
          + .cb-band-hero-desc {
            margin-top: 32px;
          }

          @include media-breakpoint-only("xs", $grid-breakpoints) {
            font-size: 40px;
            line-height: 48px;

            + .cb-band-hero-desc {
              margin-top: 24px;
            }
          }
        }

        .cb-text-overline {
          margin-bottom: 40px;

          @include media-breakpoint-only("2xl", $grid-breakpoints) {
            margin-bottom: 32px;
          }

          @include media-breakpoint-only("xs", $grid-breakpoints) {
            margin-bottom: 24px;
          }
        }
      }
    }

    // ------------------------------------ BACKGROUND
    &.cb-band-hero-bg {
      .cb-btn-row {
        margin-top: 24px;
      }
    }

    // ------------------------------------ FOCUS
    &.cb-band-hero-focus {
      .row {
        &:first-of-type {
          min-height: auto !important;
        }
      }
      @include media-breakpoint-down("lg", $grid-breakpoints) {
        .container {
          max-width: 100%;
        }
      }
      .cb-band-hero-content {
        padding: 0 12px !important;
      }

      .cb-band-hero-title {
        font-size: 64px;
        line-height: 72px;
        letter-spacing: -1px;
      }

      .cb-btn-row {
        margin-top: 24px;
      }

      @include media-breakpoint-only("xs", $grid-breakpoints) {
        .cb-band-hero-content {
          padding: 24px 24px 48px 24px !important;
          .cb-text-overline:first-child {
            margin-top: 24px;
          }
        }
        .cb-band-hero-title {
          font-size: 40px !important;
          line-height: 48px !important;

          + .cb-band-hero-desc {
            margin-top: 24px;
          }
        }
      }

      @include media-breakpoint-only("sm", $grid-breakpoints) {
        h1 {
          font-size: 36px !important;
          line-height: 42px !important;
          letter-spacing: -1px !important;

          + .cb-band-hero-desc {
            margin-top: 16px;
          }
        }

        .cb-band-hero-desc {
          font-size: 21px !important;
          line-height: 24px;
        }
      }

      @include media-breakpoint-only("md", $grid-breakpoints) {
        .cb-band-hero-title {
          font-size: 48px;
          line-height: 54px;
          letter-spacing: -1px;

          + .cb-band-hero-desc {
            margin-top: 16px;
          }
        }

        .cb-band-hero-desc {
          font-size: 24px !important;
          line-height: 32px;
        }
        img {
          max-height: 480px;
        }
      }
    }

    // ------------------------------------ CARD
    &.cb-band-hero-card {
      position: relative;
      @include media-breakpoint-up("lg", $grid-breakpoints) {
        .cb-date-time-tags {
          p {
            font-size: 1rem;
            line-height: 1.5em;
          }
        }
      }
      @include media-breakpoint-up("md", $grid-breakpoints) {
        .cb-band-hero-card-polygon {
          display: none;
        }
      }
      @include media-breakpoint-down("sm", $grid-breakpoints) {
        .cb-band-hero-card-polygon {
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          shape-outside: polygon(0px 300px, 100% 300px, 99.87% 0px, 0px 162px);
          clip-path: polygon(0px 300px, 100% 300px, 99.87% 0px, 0px 162px);
          // background: aquamarine;
          height: 300px;
          width: 100%;
        }
        .cb-card {
          margin-bottom: 72px;
        }
        .cb-band-hero-content {
          padding-bottom: 64px;
        }
      }

      // ------------------ IDENTITY CARD
      .cb-card {
        &.cb-identity-card {
          width: auto;
          min-width: auto;
          @include media-breakpoint-up("md", $grid-breakpoints) {
            padding: 32px;
          }
          @include media-breakpoint-up("xl", $grid-breakpoints) {
            padding: 48px;
          }
          @media (max-width: 518px) {
            p {
              width: auto;
            }
          }
        }
      }
    }

    // ------------------------------------ SVG BACKGROUND PATTERN
    &.cb-band-svg-pattern {
      // Palette
      position: relative;
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      @each $key, $value in $paletteColors {
        &.cb-palette-#{$key} {
          background-color: $value;
        }
      }
    }

    // ------------------------------------ ILLUSTRATION
    &.cb-band-hero-illustration {
      .row {
        &:first-of-type {
          min-height: 416px !important;
          align-items: unset;
        }
      }

      .cb-band-hero-content {
        padding: 64px 0 !important;

        @media (max-width: 375px) {
          padding: 48px 0 !important;
        }

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          text-align: center;
        }
        .cb-band-hero-title {
          font-weight: 900;
          font-size: 3rem;
          @include media-breakpoint-only("xs", $grid-breakpoints) {
            font-size: 2rem;
          }
          + .cb-band-hero-desc {
            margin-top: 8px;
          }
        }
        .cb-band-hero-desc {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5em;
        }
      }
      .cb-btn-row {
        margin-top: 8px !important;
      }
    }
    // ------------------------------------ CONDENSED
    &.cb-band-hero-condensed {
      .row {
        &:first-of-type {
          min-height: 288px;

          @include media-breakpoint-down("md", $grid-breakpoints) {
            min-height: 288px;
          }
        }
      }
      .cb-band-hero-content {
        padding: 48px 0;
        .cb-text-overline {
          display: none;
        }
        .cb-band-hero-desc {
          font-size: 1.5rem;
          line-height: 1.33em;
        }
        // .cb-btn-row {
        //   margin-top: 32px;
        // }
      }
    }
    // ------------------------------------ PALETTE
    @each $key, $value in $paletteColors {
      &.cb-palette-#{$key} {
        background-color: $value;
      }
    }
  }
}

.cb-svg-code {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

use.cb-svg-use {
  stroke: $black1;
}

@each $key, $value in $paletteSVGColors {
  .cb-palette-#{$key} {
    use.cb-svg-use {
      stroke: $value;
    }
  }
}
.cb-svg-pattern-code {
  width: 0;
  height: 0;
}

.cb-svg-pattern {
  path,
  rect,
  line {
    stroke: inherit;
  }
}
// ------------------------------------ BOUNCING BALL ANIMATION EFFECT

@keyframes grow {
  from {
    bottom: 0;
    margin-left: -100px;
    height: 50px;
    width: 200px;
  }

  to {
    bottom: 20px;
    margin-left: 0;
    height: 10px;
    width: 15px;
  }
}

@keyframes bounce {
  from {
    bottom: 20px;
  }

  to {
    bottom: 100%;
  }
}
