// -----------------------------
// CB Pagination
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/link.scss";

.cb-pagination {
  padding: 0;
  margin: 0;
  font-size: 0;
  display: inline-flex;
  flex-wrap: wrap;
  a {
    @include black-link();
    &:active,
    &.cb-active {
      color: $black1;
    }
    &.cb-disabled,
    &:disabled {
      color: $gray3;
    }
  }
  & > li {
    display: inline-flex;
    .cb-btn-square {
      border-color: $gray4;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;
      &.cb-active {
        background-color: $gray3;
        border: 1px solid $gray4 !important;
      }

      &:focus,
      &.cb-focus {
        border: 1px solid $black1;
      }
      &:disabled,
      &.cb-disabled {
        &.cb-active,
        &.active {
          color: $gray5;
        }
      }
    }
    &:first-child {
      .cb-btn-square {
        border-left-width: 1px;
        border-top-left-radius: $btn-border-radius-input;
        border-bottom-left-radius: $btn-border-radius-input;
      }
    }
    &:last-child {
      .cb-btn-square {
        border-right-width: 1px;
        border-top-right-radius: $btn-border-radius-input;
        border-bottom-right-radius: $btn-border-radius-input;
      }
    }
  }
}

.cb-page-size {
  display: inline-flex;
  flex-wrap: wrap;
  > span {
    margin-right: 8px;
    + span {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  a {
    // @include palette-link($blue5);
    @include palette-link($blue5, $blue5);
    &:disabled {
      color: $gray3;
    }
    &.cb-active {
      font-weight: 700;
      color: $link-color-black;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:hover,
      &:focus,
      &:visited {
        color: $link-color-black;
      }
      &:active {
        color: $link-active-color-black;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-flex;
      &::after {
        content: "\007C";
        display: inline-flex;
        color: $black1;
        padding: 0 8px;
      }
      &:last-of-type {
        &::after {
          content: "";
          padding: 0;
        }
      }
    }
  }
  nav {
    margin-right: 8px;
  }
}

.cb-pagination-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
}